import React from "react"
import { graphql } from "gatsby"
import { Grid } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"
import MdToReact from "../components/MdToReact"

export const query = graphql`
  query {
    contentfulFooterPage(slug: {eq: "ethics"}) {
      title
      slug
      body {
        json
      }
    }
  }
`

const EthicsPolicyPage = props => { 
  return (
    <Layout>
      <SEO title={props.data.contentfulFooterPage.title} />

      <Breadcrumbs title={props.data.contentfulFooterPage.title} />

      <Grid variant="textWrap">
        <MdToReact content={props.data.contentfulFooterPage.body.json} />
      </Grid>
    </Layout>
  )
}

export default EthicsPolicyPage